* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  cursor: url('./images/cursor.svg'), auto; /* Fallback to default cursor */
  font-family: 'Poppins', sans-serif; /* Apply Poppins font globally */
}

/* You can also set specific styles for headings or other elements if needed */
h1, h2, h3, h4, h5, h6, p {
  font-family: 'Poppins', sans-serif; /* Ensure Poppins for headings and paragraphs */
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  background-color: black;
}

::selection{
    background-color: #798d0a56;
}

.light_beams {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
  }
  
  .beam_1,
  .beam_2,
  .beam_3 {
    position: absolute;
    top: -200px;
    left: 50%;
    width: 10%;
    height: 170%;
    /* background: rgba(255, 255, 255, 0.1); */
    transform-origin: top center;
    pointer-events: none;
  }
  
  /* Positioning and rotating the beams for a spread-out light effect */
  .beam_1 {
    filter: blur(120px);
    transform: translateX(-120%) rotate(40deg);
    background: linear-gradient(to bottom, rgba(209, 246, 10, 0.7), transparent);
  }
  
  .beam_2 {
    filter: blur(120px);
    transform: translateX(10%) rotate(-30deg);
    background: linear-gradient(to bottom, rgba(209, 246, 10, 0.3), transparent);
  }
  
  .beam_3 {
    filter: blur(80px);
    transform: translateX(-50%) rotate(0deg);
    background: linear-gradient(to bottom, rgba(209, 246, 10, 0.4), transparent);
  }
  
  .dropdown_menu{
    transition: ease-in-out all 1.5s;
  }

  .chamfer-bottom-left {
    clip-path: polygon( 100% 5.712%,100% 5.712%,99.905% 4.798%,99.631% 3.93%,99.193% 3.122%,98.604% 2.383%,97.881% 1.727%,97.039% 1.164%,96.091% 0.705%,95.053% 0.364%,93.94% 0.15%,92.767% 0.076%,7.233% 0.076%,7.233% 0.076%,6.06% 0.15%,4.947% 0.364%,3.909% 0.705%,2.961% 1.164%,2.119% 1.727%,1.396% 2.383%,0.807% 3.122%,0.369% 3.93%,0.095% 4.798%,0% 5.712%,0% 94.185%,0% 94.185%,0.095% 95.099%,0.369% 95.966%,0.807% 96.774%,1.396% 97.513%,2.119% 98.169%,2.961% 98.733%,3.909% 99.191%,4.947% 99.532%,6.06% 99.746%,7.233% 99.82%,70.387% 99.82%,70.387% 99.82%,70.96% 99.802%,71.526% 99.75%,72.082% 99.663%,72.626% 99.543%,73.155% 99.391%,73.668% 99.207%,74.161% 98.992%,74.633% 98.747%,75.081% 98.472%,75.502% 98.169%,97.881% 80.734%,97.881% 80.734%,98.27% 80.406%,98.623% 80.057%,98.937% 79.69%,99.213% 79.305%,99.449% 78.906%,99.645% 78.493%,99.799% 78.069%,99.91% 77.636%,99.977% 77.196%,100% 76.749%,100% 5.712% );
  }

   /* Default styling for larger screens */
   .responsive_svg {
    width: 404px;
    height: 80px;
  }

  /* Media query for mobile screens */
  @media (max-width: 768px) {
    .responsive_svg {
      width: 320px;  /* Adjust width for smaller screens */
      height: 100px;  /* Adjust height for smaller screens */
    }
  }